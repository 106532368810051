import * as THREE from 'three'
import Card from '../World/Card'
import Deck from '../World/Deck'
import Piece from '../World/Piece'
import { isDesktop } from './Utils'

export default class Controls extends EventTarget {
  static HOLDING_THRESHOLD = 300
  constructor(experience) {
    super()
    this.experience = experience
    this.canvas = experience.canvas
    this.sizes = this.experience.sizes
    this.debug = this.experience.debug
    this.camera = this.experience.camera
    this.raycaster = this.experience.raycaster
    this.network = this.experience.network

    this.keysPressed = {}
    this._selectedObject = null

    window.addEventListener('keydown', (event) => {
      this.keysPressed[event.key.toLowerCase()] = true
    })

    window.addEventListener('keyup', (event) => {
      this.keysPressed[event.key.toLowerCase()] = false
      this.dispatchEvent(new Event('keyup_' + event.code))
    })

    this.mouse = new THREE.Vector2()

    if (isDesktop()) {
      this.canvas.addEventListener('mousedown', (event) => {
        if (event.button == 0) { // left mouse button
          this.computeSelectedObject()
        }
      })

      this.canvas.addEventListener('mousemove', (event) => {
        this.mouse.x = event.clientX / this.sizes.width * 2 - 1
        this.mouse.y = - (event.clientY / this.sizes.height) * 2 + 1
        this.mouse.clientX = event.clientX
        this.mouse.clientY = event.clientY
        if (this.experience.selectedObject) {
          this.raycaster.setFromCamera(this.mouse, this.camera.instance)
          const floorIntersected = this.raycaster.intersectObject(this.experience.world.floor.virtualFloorMesh)
          if (floorIntersected.length) {
            this.dispatchEvent(new CustomEvent('objectDragging', { detail: { position: floorIntersected[0].point } }))
          }
        }
      })

      this.canvas.addEventListener('mouseup', () => {
        if (this.experience.selectedObject) {
          this.dispatchEvent(new CustomEvent('objectDropped', { detail: { objectId: this.experience.selectedObject.id } }))
        }
        this.clearSelectedObject()
      })

      this.canvas.addEventListener('click', () => {
        this.computeFloorIntersect()
        this.clearSelectedObject()
      })
    } else {
      this.canvas.addEventListener('touchstart', (event) => {
        event.stopPropagation()
        this.mouse.x = event.touches[0].clientX / this.sizes.width * 2 - 1
        this.mouse.y = - (event.touches[0].clientY / this.sizes.height) * 2 + 1
        this.computeSelectedObject()
      }, {passive: true})

      this.canvas.addEventListener('touchend', (event) => {
        event.stopPropagation()
        if (this._selectedObject) {
          if (this._selectedObject == this.experience.currentObjectSelection) { // "clicking" on the same object
            this.clearSelectedObject()
          } else if ((this.experience.currentObjectSelection instanceof Card || this.experience.currentObjectSelection instanceof Piece) && this._selectedObject instanceof Deck ) {
            console.log('dropping entity on deck')
            const deck = this._selectedObject
            this.dispatchEvent(new CustomEvent('objectDragging', { detail: { position: deck.body.position } }))
            setTimeout(() => {
              this.dispatchEvent(new CustomEvent('objectDropped', { detail: { objectId: this.experience.currentObjectSelection.id } }))
              deck.onCollide(this.experience.currentObjectSelection)
              this.clearSelectedObject()
            }, 300)
          } else {
            if (this._selectedObject instanceof Deck) {
              const deck = this._selectedObject
              deck.onSelect(this.experience.playerId)
            } else {
              this.computeSelectedObject(true)  // simulate a desktop "mouseup" event
            }
          }
        } else {
          this.computeFloorIntersect()
          this.clearSelectedObject()
        }
      })
    }
  }

  computeSelectedObject(simulateMouseUp = false) {
    if (this.experience.isLocked) {
      return
    }
    this.raycaster.setFromCamera(this.mouse, this.camera.instance) // this.mouse updated in the mousemove event listener
    const objectsIntersected = this.raycaster.intersectObjects(this.experience.INTERACTIVE_OBJECTS.map(obj => obj.model || obj.mesh || obj).filter(Boolean))
    const uuidsIntersected = objectsIntersected.map(o => o.object.uuid || o.uuid)
    if (uuidsIntersected.length) {
      let firstMatchingObject = this.experience.getInteractiveObjectFromUuids(uuidsIntersected)
      if (firstMatchingObject) {
        if (this.experience.isAdmin !== true && firstMatchingObject.ownerId && firstMatchingObject.ownerId != this.experience.playerId) {
          return
        }
        if (isDesktop() || simulateMouseUp) {
          this.experience.selectedObject = firstMatchingObject
          this.experience.selectedOutlinePass.selectedObjects = [firstMatchingObject.model || firstMatchingObject.mesh || firstMatchingObject]
          this.dispatchEvent(new Event(firstMatchingObject.model?.uuid || firstMatchingObject.mesh?.uuid || firstMatchingObject.uuid))
          this.experience.isDragging = true
          if (this.experience.enableOnline) {
            this.network.broadcastEvent({ name: 'objectSelected', id: firstMatchingObject.id })
          }
        } else {
          this._selectedObject = firstMatchingObject
          setTimeout(() => {
            this._selectedObject = null
          }, Controls.HOLDING_THRESHOLD)
        }
      }
    }
  }

  computeFloorIntersect() {
    this.raycaster.setFromCamera(this.mouse, this.camera.instance) // this.mouse updated in the mousemove event listener
    const floorIntersected = this.raycaster.intersectObject(this.experience.world?.floor?.virtualFloorMesh)
    if (floorIntersected?.length) {
      this.dispatchEvent(new CustomEvent('floorClicked', { detail: { position: floorIntersected[0].point } }))
    }
  }

  clearSelectedObject() {
    this.experience.selectedObject = null
    this.experience.selectedOutlinePass.selectedObjects = []
    this.experience.isDragging = false
    this._selectedObject = null
    this.experience.currentObjectSelection = null
    this.experience.hideContextMenu()
  }
}