import * as CANNON from 'cannon-es'
import CannonDebugger from 'cannon-es-debugger'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import * as THREE from 'three'
import { AxesHelper, Vector3 } from 'three'
import monopoly from '../../../static/world_data/monopoly'
import { isDesktop, rand } from '../Utils/Utils'
import Card from './Card'
import Deck from './Deck'
import Dice from './Dice'
import Environment from './Environment'
import Floor from './Floor'
import Piece from './Piece'

export default class World extends EventTarget {
  constructor(experience, worldName) {
    super()
    this.experience = experience
    this.scene = this.experience.scene
    this.time = this.experience.time
    this.resourcesManager = this.experience.resourcesManager
    this.controls = this.experience.controls
    this.debug = this.experience.debug
    this.camera = this.experience.camera
    this.raycaster = this.experience.raycaster
    this.sizes = this.experience.sizes
    this.network = this.experience.network
    this.playerId = localStorage.getItem('playerId')

    this.spawnPositions = monopoly?.spawnPositions || []

    this.resourcesManager.addEventListener('ready', () => { this.onResourcesLoaded() })
    this.initPhysic()
    
    if (this.debug.active) {
      const axesHelper = new AxesHelper(1)
      // this.scene.add(axesHelper)
    }
  }

  async onResourcesLoaded() {
    this.environment = new Environment(this.experience)
    this.floor = new Floor(this.experience, 'board', monopoly.boardTexture)
    this.scoreUnit = monopoly?.scoreUnit || ''

    // UI
    this.point = { element: document.querySelector('.point-0') }
    this.tooltipAvatar = { element: document.querySelector('.avatar-tooltip') }

    // Online
    this.experience.addEventListener('message', (event) => {
      const data = event.detail.message
      const { name, id, angle, x, y, z, emitterId } = data
      if (emitterId == this.playerId) {
        console.warn('event from myself')
        return
      }
      const emitterFloatingAvatarElement = document.getElementById(emitterId)
      const emitterBoardElement = document.getElementById(`player-board-${emitterId}`)
      const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
      
      let elevatedPosition
      if (object) {
        elevatedPosition = name === 'objectDragging' ? new THREE.Vector3(x, y, z) : new THREE.Vector3(object.body.position.x, object.body.position.y, object.body.position.z)
        const screenPosition = elevatedPosition.clone()
        screenPosition.project(this.camera.instance)
        const translateX = screenPosition.x * this.sizes.width * 0.5
        const translateY = - screenPosition.y * this.sizes.height * 0.5
        emitterFloatingAvatarElement.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
      }

      if (name === 'rollDice') {
        const dice = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        dice?.roll(angle, x, y, z, emitterId)
        if (emitterId != this.playerId) {
          emitterBoardElement?.classList.add('active')
          setTimeout(() => {  emitterBoardElement?.classList.remove('active') }, 300)
        }
      } else if (name === 'objectDragging') {
        if (object) {
          emitterFloatingAvatarElement?.classList.add('visible')
          emitterBoardElement?.classList.add('active')
          object?.moveTo(elevatedPosition, emitterId)
        }
      } else if (name === 'objectDropped') {
        const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        if (object && object?.onDropped) {
          object?.onDropped()
          setTimeout(() => {
            emitterFloatingAvatarElement?.classList.remove('visible')
            emitterBoardElement?.classList.remove('active')
          }, 100)
        }
      } else if (name === 'flip') {
        const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        if (object) {
          emitterFloatingAvatarElement?.classList.add('visible')
          object?.flip(emitterId, () => { emitterFloatingAvatarElement?.classList.remove('visible') })
        }
      } else if (name === 'reset') {
        const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        object?.reset(emitterId)
      } else if (name === 'keyup_q') {
        const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        if (object?.rotateLeft) {
          emitterFloatingAvatarElement?.classList.add('visible')
          object.rotateLeft(emitterId)
          setTimeout(() => emitterFloatingAvatarElement?.classList.remove('visible'), 100)
        }
      } else if (name === 'keyup_e') {
        const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        if (object?.rotateLeft) {
          emitterFloatingAvatarElement?.classList.add('visible')
          object.rotateRight(emitterId)
          setTimeout(() => emitterFloatingAvatarElement?.classList.remove('visible'), 100)
        }
      } else if (name === 'objectSelected') {
        // console.log('objectSelected', emitterId, emitterId == this.playerId)
        const object = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == id)
        if (object?.onSelect) {
          object.onSelect(emitterId)
        }
      } else if (name === 'objectInstancied') {
        elevatedPosition = new THREE.Vector3(x, 1, z)
        this.experience.instanciateInteractiveObject(data.type, data, elevatedPosition)
      }
    })

    if (this.experience.enableOnline) {
      const dices = await this.network.fetchDocuments('play_dices')
      dices.forEach(document => {
        const dice = new Dice(this.experience, document.id, 'diceModel', new THREE.Vector3(document.x, document.y, document.z))
        if (typeof document?.lastResult === 'number') {
          dice.rotateTo(document.lastResult)
        }
        dice.addEventListener('rolled', async () => {
          if (dice.emitterId == this.playerId) {
            await this.network.updateDocument('play_dices', dice.id, { lastResult: dice.getResult(), ...dice.body.position })
          }
        })
        onSnapshot(doc(this.network.db, 'play_dices', dice.id), async (newDocSnap) => {
          const { x, y, z, lastResult } = newDocSnap.data()
          if (dice.emitterId != this.playerId) {
            if (lastResult != dice.getResult()) {
              console.log('corrected')
              dice.rotateTo(lastResult)
              dice.body.position.set(x, y, z)
            }
          }
        })
        this.experience.INTERACTIVE_OBJECTS.push(
          dice
        )
      })
      onSnapshot(collection(this.network.db, 'play_pieces'), async (querySnapshot) => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const document = change.doc.data()
            document.id = change.doc.id
            const { modelName, x, y, z, rotationY, deckType, ownerId } = document
            if (modelName) {
              const piece = new Piece({
                experience: this.experience,
                id: document.id,
                modelName,
                deckType,
                startingPosition: new THREE.Vector3(x||0, y||0, z||0),
                startingRotationInRad: rotationY ? THREE.MathUtils.degToRad(rotationY) : null
              })
              if (ownerId) {
                piece.setOwner(ownerId)
              }
              this.experience.INTERACTIVE_OBJECTS.push(piece)
            }
          }
          if (change.type === 'modified') {
            const data = change.doc.data()
            const piece = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == change.doc.id)
            if (piece) {
              piece.setOwner(data?.ownerId)
            }
          }
          if (change.type === 'removed') {
            const piece = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == change.doc.id)
            if (piece) {
              piece.dispose()
            }
          }
        })
      })
      onSnapshot(collection(this.network.db, 'play_decks'), async (querySnapshot) => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const document = change.doc.data()
            document.id = change.doc.id
            const { textureName, entities, nbEntities, entityModelName, isLocked, x, y, z, rotationY, deckType, modelName, isSearchable, absorbOnTop, isDealLocked, flipOnDeal } = document
            if (textureName || modelName) {
              const deck =  new Deck({
                experience: this.experience,
                id: document.id,
                modelName,
                textureName,
                deckType,
                entities,
                nbEntities,
                entityModelName,
                isSearchable,
                isLocked,
                absorbOnTop,
                isDealLocked,
                flipOnDeal,
                position: new THREE.Vector3(x, y, z),
                rotationY: THREE.MathUtils.degToRad(rotationY || 0)
              })
              this.experience.INTERACTIVE_OBJECTS.push(deck)
            }
          }
          if (change.type === 'modified') {
            // console.log('Modified deck: ', change.doc.data())
            const data = change.doc.data()
            const deck = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == change.doc.id)
            this.dispatchEvent(new CustomEvent(deck.id, { detail: { entities: data.entities, isDealLocked: data.isDealLocked == true } }))
          }
          if (change.type === 'removed') {
            const deck = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == change.doc.id)
            if (deck) {
              deck.dispose()
            }
          }
        })
      })
      onSnapshot(collection(this.network.db, 'play_cards'), async (querySnapshot) => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'removed') {
            const card = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == change.doc.id)
            if (card) {
              card.dispose()
            }
          } else if (change.type == 'added') {
            const doc = change.doc.data()
            doc.id = change.doc.id
            this.experience.instanciateInteractiveObject('Card', doc, new THREE.Vector3(doc.x, doc.y, doc.z))
          }
        })
      })
    } else {
      monopoly?.objects?.forEach(obj => {
        switch (obj.type) {
          case 'Dice':
            const dice = new Dice(this.experience, obj.id, obj.modelName, new Vector3(obj.position.x, obj.position.y, obj.position.z))
            this.experience.INTERACTIVE_OBJECTS.push(dice)
            break
          case 'Piece':
            const piece = new Piece({
              experience: this.experience,
              id: obj.id,
              modelName: obj.modelName,
              deckType: obj.deckType,
              startingPosition: new Vector3(obj.position?.x || 0, obj.position?.y || 0, obj.position?.z || 0),
              startingRotationInRad: THREE.MathUtils.degToRad(obj.rotationY || 0),
            })
            this.experience.INTERACTIVE_OBJECTS.push(piece)
            break
          case 'Deck':
            const deck = new Deck({
              experience: this.experience,
              id: obj.id,
              modelName: obj.modelName,
              textureName: obj.textureName,
              deckType: obj.deckType,
              entities: obj.entities,
              nbEntities: obj.nbEntities,
              entityModelName: obj.entityModelName,
              isSearchable: obj.isSearchable,
              isLocked: obj.isLocked,
              absorbOnTop: obj.absorbOnTop,
              isDealLocked: obj.isDealLocked,
              flipOnDeal: obj.flipOnDeal,
              position: new Vector3(obj.position.x, obj.position.y, obj.position.z),
              rotationY: THREE.MathUtils.degToRad(obj.rotationY || 0)
            })
            this.experience.INTERACTIVE_OBJECTS.push(deck)
            break
          case 'Card':
            const card = new Card(this.experience, obj.id, obj.deckType, obj.textureName, obj.backTextureName, undefined, undefined, undefined, new Vector3(obj.position.x, obj.position.y, obj.position.z), THREE.MathUtils.degToRad(obj.rotationX || 0), THREE.MathUtils.degToRad(obj.rotationZ || 0), obj.isFlipped)
            this.experience.INTERACTIVE_OBJECTS.push(card)
            break
        }
      })
    }
    document.querySelector('body').classList.remove('hidden')
  }

  initPhysic() {
    this.physicalWorld = new CANNON.World()
    this.physicalWorld.broadphase = new CANNON.SAPBroadphase(this.physicalWorld)
    this.physicalWorld.allowSleep = true
    this.physicalWorld.gravity.set(0, - 9.82, 0)
    
    // Default material
    this.physicalWorld.defaultMaterial = new CANNON.Material('default')

    this.initDicesCage()

    this.cannonDebugger = new CannonDebugger(this.scene, this.physicalWorld, {
      color: 0x00ff00
    })
  }

  initDicesCage() {
    const initialY = 10
    const axisY = new CANNON.Vec3(0,1,0)
    const wallThickness = 0.2
    const shape = new CANNON.Box(new CANNON.Vec3(5.5 * 0.5, 6 * 0.5, wallThickness * 0.5))
    const shape2 = new CANNON.Box(new CANNON.Vec3(7 * 0.5, 6 * 0.5, wallThickness * 0.5))

    const wallE = new CANNON.Body({
      mass: 10000,
      position: new CANNON.Vec3(3.5, initialY, 0),
      shape: shape,
      material: this.physicalWorld.defaultMaterial,
      collisionFilterGroup: 1,
      collisionFilterMask: 1
    })
    wallE.quaternion.setFromAxisAngle(axisY, Math.PI / 2)
    this.physicalWorld.addBody(wallE)

    const wallW = new CANNON.Body({
      mass: 100000,
      position: new CANNON.Vec3(-3.5, initialY, 0),
      shape: shape,
      material: this.physicalWorld.defaultMaterial,
      collisionFilterGroup: 1,
      collisionFilterMask: 1
    })
    wallW.quaternion.setFromAxisAngle(axisY, Math.PI / 2)
    this.physicalWorld.addBody(wallW)

    const wallS = new CANNON.Body({
      mass: 100000,
      position: new CANNON.Vec3(0, initialY, -3),
      shape: shape2,
      material: this.physicalWorld.defaultMaterial,
      collisionFilterGroup: 1,
      collisionFilterMask: 1
    })
    this.physicalWorld.addBody(wallS)

    const wallN = new CANNON.Body({
      mass: 100000,
      position: new CANNON.Vec3(0, initialY, 3),
      shape: shape2,
      material: this.physicalWorld.defaultMaterial,
      collisionFilterGroup: 1,
      collisionFilterMask: 1
    })
    this.physicalWorld.addBody(wallN)
  }

  async rollDices() {
    if (this.experience.isLocked) {
      return
    }
    const dices = this.experience.INTERACTIVE_OBJECTS.filter(obj => typeof obj.nbRoll === 'number')
    dices?.forEach((dice) => {
      const randomParam = {
        angle: Math.round(rand(0, 361)) * (Math.PI/180),
        x: rand(-3, 3),
        y: rand(2, 5),
        z: rand(-3, 3)
      }
      dice.roll(randomParam.angle, randomParam.x, randomParam.y, randomParam.z, this.experience.playerId)
      if (!this.experience.playerId) {
        return
      }
      this.network.broadcastEvent({ name: 'rollDice', id: dice.id, ...randomParam, emitterId: this.experience.playerId })
    })
  }

  update() {
    const disposedObjects = this.experience.INTERACTIVE_OBJECTS.filter(o => o.toDelete == true)
    this.experience.INTERACTIVE_OBJECTS = this.experience.INTERACTIVE_OBJECTS.filter(o => o.toDelete != true)
    if (isDesktop()) {
      this.raycaster.setFromCamera(this.controls.mouse, this.camera.instance)
      if (this.experience.INTERACTIVE_OBJECTS.length) {
        const objectsIntersected = this.raycaster.intersectObjects(this.experience.INTERACTIVE_OBJECTS.map(o => o?.model || o?.mesh || o))
        const uuidsIntersected = objectsIntersected.map(o => o?.object.uuid || o?.uuid)
        if (uuidsIntersected.length) {
          // document.querySelector('.webgl').classList.add('pointer')
          const hoveredObject = this.experience.getInteractiveObjectFromUuids(uuidsIntersected)
          if (this.experience.isLocked !== true && (hoveredObject.ownerId == this.playerId || !hoveredObject.ownerId || this.experience.isAdmin)) {
            document.querySelector('.webgl').classList.add('pointer')
            this.experience.hoveredObject = hoveredObject
          } else if (hoveredObject.ownerId) {
            document.querySelector('.webgl').classList.add('forbidden')
            const player = this.experience.players[hoveredObject.ownerId]
            if (player?.imageUrl) {
              this.tooltipAvatar.element.style.backgroundImage = `url('${player.imageUrl}')`
            } else {
              this.tooltipAvatar.element.innerHTML = `<div class="label">${player?.username[0]}</div>`
            }
            this.tooltipAvatar.element.style.left = this.controls.mouse.clientX + 'px'
            this.tooltipAvatar.element.style.top = (this.controls.mouse.clientY - 25) + 'px'
            this.tooltipAvatar.element.classList.add('visible')
          }
          if (this.experience.hoveredObject?.model || this.experience.hoveredObject?.mesh) {
            this.experience.hoverOutlinePass.selectedObjects = [this.experience.hoveredObject.model || this.experience.hoveredObject?.mesh]
            if (this.experience.hoveredObject.label) {
              this.point.element.innerHTML = `<div class="label">${this.experience.hoveredObject.label || ''}</div>`
              this.point.element.style.left = this.controls.mouse.clientX + 'px'
              this.point.element.style.top = (this.controls.mouse.clientY - 15) + 'px'
              this.point.element.classList.add('visible')
            }
          }
        } else {
          document.querySelector('.webgl').classList.remove('pointer')
          document.querySelector('.webgl').classList.remove('forbidden')
          this.point?.element.classList.remove('visible')
          this.tooltipAvatar?.element.classList.remove('visible')
          const floorIntersect = this.raycaster.intersectObjects(this.floor.mesh)
          if (floorIntersect.length) {
            this.experience.floorIntersect = floorIntersect[0]
          } else {
            this.experience.floorIntersect = null
          }
          this.experience.hoverOutlinePass.selectedObjects = []
          this.experience.hoveredObject = null
        }
        
      } else {
        this.experience.hoverOutlinePass.selectedObjects = []
        this.experience.hoveredObject = null
      }
    }

    if (disposedObjects.length) {
      disposedObjects.forEach(o => {
        if (o.body) {
          this.physicalWorld.removeBody(o.body)
        }
      })
    }

    this.physicalWorld.step(1 / 60, this.time.delta, 3)

    this.experience.INTERACTIVE_OBJECTS.forEach(object => {
      object?.update && object?.update()
    })

    if (this.debug.active) {
      this.cannonDebugger?.update()
    }
  }
}