import * as CANNON from 'cannon-es'
import { collection, onSnapshot } from 'firebase/firestore'
import { Howl } from 'howler'
import * as THREE from 'three'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js'
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js'
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js'
import * as packageJson from '../../package.json'
import assets from '../../static/assets'
import Camera from './Camera'
import Renderer from './Renderer'
import Controls from './Utils/Controls'
import Debug from './Utils/Debug'
import GoDice from './Utils/GoDice'
import NetworkService from './Utils/Network.js'
import ResourcesManager from './Utils/ResourcesManager'
import Sizes from './Utils/Sizes'
import Time from './Utils/Time'
import { isMobile } from './Utils/Utils.js'
import Card from './World/Card'
import Piece from './World/Piece'
import World from './World/World'

export default class Experience extends EventTarget {
  constructor(canvasElement) {
    super()
    const urlParams = new URL(document.location).searchParams
    const playerId = urlParams.get('playerId')
    if (playerId) {
      localStorage.setItem('playerId', playerId)
    }
    this.playerId = localStorage.getItem('playerId')

    localStorage.setItem('version', packageJson.version)

    // Init props
    this.canvas = canvasElement
    this.debug = new Debug()
    this.sizes = new Sizes()
    this.time = new Time()
    this.scene = new THREE.Scene()
    this.uiScene = new THREE.Scene()
    this.raycaster = new THREE.Raycaster()
    this.network = new NetworkService(this)
    this.camera = new Camera(this)
    this.resourcesManager = new ResourcesManager(assets)
    this.controls = new Controls(this)
    this.renderer = new Renderer(this)
    this.uiRenderer = new CSS3DRenderer()
    this.uiRenderer.setSize(window.innerWidth, window.innerHeight)
    this.uiRenderer.domElement.style.position = 'absolute'
    this.uiRenderer.domElement.style.top = 0
    this.uiRenderer.domElement.style.zIndex = 0
    document.body.appendChild(this.uiRenderer.domElement)
    this.world = new World(this)

    this.debugEl = document.querySelector('.debug')

    this.INTERACTIVE_OBJECTS = []
    this.INTERACTIVE_ZONES = []
    this.floorIntersect = null
    this.selectedObject = null
    this.currentObjectSelection = null
    this.hoveredObject = null
    this.isDragging = false

    this.music = new Howl({
      src: ['https://res.cloudinary.com/raphkun-learn/video/upload/v1731273348/f996nxjwgx7cp0gzrfjq.mp3'],
      autoplay: false,
      loop: true,
      volume: 0.5
    })
    this.alertSound = new Howl({ src: ['sounds/alert.mp3'] })
    this.receiptSound = new Howl({ src: ['sounds/receipt.mp3'] })
    this.cashSound = new Howl({ src: ['sounds/cash.mp3'] })

    this.sizes.addEventListener('resize', () => {
      this.resize()
    })

    this.time.addEventListener('tick', this.update.bind(this))

    this.controls.addEventListener('keyup_KeyI', () => {
      console.log(this.hoveredObject || this.currentObjectSelection)
    })
    this.controls.addEventListener('keyup_KeyD', () => {
      if (this.isAdmin && this.hoveredObject?.id && this.hoveredObject?.dispose && this.playerId) {
        this.hoveredObject.dispose(this.playerId)
      }
    })
    this.controls.addEventListener('keyup_KeyS', () => {
      if (this.hoveredObject?.id && this.hoveredObject?.dispose && this.playerId) {
        this.hoveredObject.shuffle(this.playerId)
      }
    })
    this.controls.addEventListener('keyup_KeyQ', () => {
      if (typeof this.hoveredObject?.rotateLeft == 'function') {
        this.hoveredObject?.rotateLeft(this.playerId)
        this.network.broadcastEvent({ name: 'keyup_q', id: this.hoveredObject?.id })
      }
    })
    this.controls.addEventListener('keyup_KeyE', () => {
      if (typeof this.hoveredObject?.rotateLeft == 'function') {
        this.hoveredObject?.rotateRight(this.playerId)
      }
      this.network.broadcastEvent({ name: 'keyup_e', id: this.hoveredObject?.id })
    })
    this.controls.addEventListener('keyup_KeyR', () => {
      if (this.hoveredObject?.reset) {
        this.hoveredObject.reset(this.playerId)
        this.network.broadcastEvent({ name: 'reset', id: this.hoveredObject?.id })
      }
    })
    this.controls.addEventListener('keyup_KeyU', async() => {
      if (this.isAdmin && this.hoveredObject?.id && this.hoveredObject?.dispose && this.playerId) {
        this.hoveredObject.setOwner(null)
        this.network.updateDocument('play_pieces', this.hoveredObject.id, { ownerId: null })
      }
    })
    this.controls.addEventListener('keyup_KeyF', () => {
      if (this.hoveredObject?.flip && (!this.hoveredObject.ownerId || this.hoveredObject.ownerId == this.playerId)) {
        this.hoveredObject.flip(this.playerId)
        this.network.broadcastEvent({ name: 'flip', id: this.hoveredObject?.id })
      }
    })
    this.controls.addEventListener('keyup_Space', () => {
      this.focusToPlayer()
    })
    this.controls.addEventListener('floorClicked', (event) => {
      if (isMobile() && this.currentObjectSelection) {
        const objectToMove = this.INTERACTIVE_OBJECTS.find(obj => obj.id == this.currentObjectSelection.id)
        const elevatedPosition = new THREE.Vector3(event.detail.position.x, 1.2, event.detail.position.z)
        objectToMove?.moveTo(elevatedPosition, this.playerId, () => {
          objectToMove?.onDropped && objectToMove?.onDropped()
          this.network.broadcastEvent({ name: 'objectDropped', id: objectToMove?.id })
        })
        this.network.broadcastEvent({ name: 'objectDragging', id: objectToMove?.id, ...elevatedPosition })
      }
      if (!this.currentObjectSelection && !this.selectedObject) {
        const {x, y} = this.getWindowCoordinates(new THREE.Vector3(event.detail.position.x, event.detail.position.y, event.detail.position.z))
        this.INTERACTIVE_ZONES.forEach(zone => {
          const rect = zone.element.getBoundingClientRect()
          const isInZone = (rect.top <= y && y <= rect.top + rect.height && rect.left <= x && x <= rect.left + rect.width)
          if (isInZone) {
            this.focusToPlayer(zone.element.dataset.playerId)
          }
        })
      }
    })
    this.controls.addEventListener('objectDragging', (event) => {
      const position = event.detail.position
      if (this.selectedObject && position) {
        const elevatedPosition = new THREE.Vector3(position.x, 1.2, position.z)
        if (this.selectedObject.moveTo && this.selectedObject.draggable !== false) {
          this.selectedObject.moveTo(elevatedPosition, this.playerId)
          this.network.broadcastEvent({ name: 'objectDragging', id: this.selectedObject?.id, ...elevatedPosition })
        }
      }
    })
    this.controls.addEventListener('objectDropped', (event) => {
      const object = this.INTERACTIVE_OBJECTS.find(obj => obj.id == event?.detail?.objectId)
      if (object?.onDropped) {
        object.onDropped()
        this.network.broadcastEvent({ name: 'objectDropped', id: this.selectedObject?.id })
      }
      this.selectedObject = null
    })

    document.getElementById('btnRotateLeft').addEventListener('click', () => {
      if (typeof this.currentObjectSelection?.rotateLeft == 'function') {
        this.currentObjectSelection.rotateLeft(this.playerId)
        this.network.broadcastEvent({ name: 'keyup_q', id: this.currentObjectSelection?.id })
      }
    })

    document.getElementById('btnRotateRight').addEventListener('click', () => {
      if (typeof this.currentObjectSelection?.rotateLeft == 'function') {
        this.currentObjectSelection.rotateRight(this.playerId)
        this.network.broadcastEvent({ name: 'keyup_e', id: this.currentObjectSelection?.id })
      }
    })

    document.getElementById('btnFlip').addEventListener('click', () => {
      if (typeof this.currentObjectSelection?.flip == 'function') {
        this.currentObjectSelection.flip(this.playerId)
        this.network.broadcastEvent({ name: 'flip', id: this.currentObjectSelection?.id })
      }
    })

    window.onbeforeunload = () => {
      this.network.updateDocument('play_users', this.playerId, {})
    }

    this.initGoDice()

    this.initPostProcessing()
    
    // Network
    let enableOnline = urlParams.get('enableOnline')
    if (enableOnline) {
      localStorage.setItem('enableOnline', enableOnline)
    }
    window.history.replaceState({}, document.title, '/')

    this.enableOnline = typeof localStorage.getItem('enableOnline') == 'string' ? localStorage.getItem('enableOnline') === 'true' : true
    this.players = {}
    if (this.enableOnline) {
      this.network.fetchDocuments('play_users').then((docs) => {
        docs.forEach((doc, i) => {
          this.players[doc.id] = doc
          this.createPlayerMiniAvatar(doc.id, doc)
          let spawnPos = {}
          if (i < 4) {
            spawnPos = this.world.spawnPositions[i]
            this.players[doc.id].spawnPosition = spawnPos
            this.createPlayerZone(doc, spawnPos)
            this.createPlayerVirtualFloor(doc, spawnPos)
          }
        })
        if (this.playerId && !this.players[this.playerId]) {
          this.playerId = null
          localStorage.removeItem('playerId')
          console.warn('Player not found, please refresh the page')
        }
        this.renderPodiumAvatars()
        this.isAdmin = this.players[this.playerId]?.isAdmin == true
        this.isLocked = !this.playerId || this.players[this.playerId]?.isLocked == true
        if (this.isLocked) {
          document.querySelector('html').classList.add('locked')
        } else {
          document.querySelector('html').classList.remove('locked')
        }
        this.createCurrentPlayerButton(this.players[this.playerId])
        this.network.initOnline()
        this.network.initSocketIO()
      })
      onSnapshot(collection(this.network.db, 'play_users'), async(querySnapshot) => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'modified') {
            const data = change.doc.data()
            const playerId = change.doc.id
            const { score, username, imageUrl } = data
            this.updatePlayerInfo(change.doc.id, data)
            if (this.players[playerId]) {
              if (playerId == this.playerId) {
                this.isAdmin = data.isAdmin
                this.isLocked = data.isLocked
                if (score > this.players[playerId].score) {
                  this.cashSound.play()
                }
              }
              this.players[playerId].username = username
              this.players[playerId].imageUrl = imageUrl
              this.players[playerId].score = score
            }
            if (this.isLocked) {
              document.querySelector('html').classList.add('locked')
            } else {
              document.querySelector('html').classList.remove('locked')
            }
            if (this.isAdmin) {
              document.querySelector('.admin-icon')?.classList.remove('hidden')
            } else {
              document.querySelector('.admin-icon')?.classList.add('hidden')
            }
            this.renderPodiumAvatars()
          }
        })
      })
    } else {
      this.isAdmin = localStorage.getItem('isAdmin') === 'true'
      this.isLocked = localStorage.getItem('isLocked') === 'true'
    }

    // UI
    const html = document.querySelector('html')
    if (isMobile()) {
      html.classList.add('mobile')
    } else {
      html.classList.add('desktop')
    }

    this.dialogSelectedElement = null
    this.dialog = document.querySelector("dialog")
    this.dialog.addEventListener('click', (event) => {
      event.stopPropagation()
      var rect = this.dialog.getBoundingClientRect()
      var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX && event.clientX <= rect.left + rect.width)
      if (!isInDialog) {
        this.dialog.classList.add('closing')
        setTimeout(() => {
          this.dialog.close()
          this.dialog.innerHTML = ''
          this.dialog.classList.remove('closing')
        }, 200)
      } else {
        if (!event.target.closest('form')) {
          this.dialog.querySelectorAll('.selected').forEach(el => el.classList.remove('selected'))
          document.getElementById('btnSubmitDialog').setAttribute('disabled', true)
          if (event.target.dataset?.name) {
            this.dialogSelectedElement = event.target
            this.dialog.querySelector(`[data-name="${event.target.dataset.name}"] `).classList.add('selected')
            document.getElementById('btnSubmitDialog').removeAttribute('disabled')
          }
          if (this.dialog.classList.contains('closeonbgclick')) {
            this.dialog.close()
          }
        }
      }
    })
    this.dialog.addEventListener('close', () => {
      this.dialog.innerHTML = ''
      this.dialog.classList.remove('closeonbgclick')
      this.dialogSelectedElement = null
      this.dialog.dataset.callerId = null
    })
  }

  // TODO: move to UIService
  createPlayerMiniAvatar(playerId, player) {
    if (!player) {
      return
    }
    
    const avatarDiv = document.createElement('div')
    avatarDiv.id = playerId
    avatarDiv.classList.add('avatar', playerId)
    
    const labelDiv = document.createElement('div')
    labelDiv.classList.add('label')
    if (player.imageUrl) {
      labelDiv.style.backgroundImage = `url('${player.imageUrl}')`
    } else {
      labelDiv.innerHTML = player.username?.[0]
    }
    avatarDiv.appendChild(labelDiv)
    document.body.append(avatarDiv)
  }

  renderPodiumAvatars() {
    let players = Object.values(this.players)
    players.sort((a, b) => {
      if (b.score === a.score) {
        return a.username.localeCompare(b.username)
      }
      return b.score - a.score
    })
    const topLeftNav = document.querySelector('nav.top-left')
    topLeftNav.innerHTML = ''
    players.map(player => {
      const avatarDiv = document.createElement('div')
      avatarDiv.classList.add('w-10', 'h-10', 'rounded-full', 'bg-center', 'bg-contain', 'cursor-pointer')
      if (!player.imageUrl) {
        avatarDiv.innerHTML = player.username?.[0]
      } else {
        avatarDiv.style.backgroundImage = `url('${player.imageUrl}')`
      }
      if (player.isAdmin) {
        avatarDiv.innerHTML += '<i class="absolute text-sm fa-solid fa-binoculars mt-7 ml-4 text-white"></i>'
      }
      avatarDiv.setAttribute('title', `${player.username} - ${player.score}`)
      topLeftNav.append(avatarDiv)
      avatarDiv.addEventListener('click', () => {
        this.showTradeDialog(player.id)
      })
    })
  }

  createPlayerZone(player, position) {
    const element = document.createElement('div')
    element.id = `player-board-${player.id}`
    element.dataset.playerId = player.id
    element.classList.add('player-board')
    element.innerHTML = `
      <img class="player-avatar" src='${player?.imageUrl || 'https://i.pravatar.cc/300'}' />
      <div class="player-info">
        <span class="text">${player?.username || 'guest'}</span>
        <span class="score">${player?.score} ${this.world?.scoreUnit || ''}</span>
      </div>
    `

    var object = new CSS3DObject(element)
    object.position.x = position?.x || 0
    object.position.y = position?.y || 0
    object.position.z = position?.z || 0
    object.rotation.x = position?.rotation?.x || 0
    object.rotation.z = position?.rotation?.z || 0
    const scale = .07
    object.scale.x = scale
    object.scale.y = scale
    this.uiScene.add(object)
    this.INTERACTIVE_ZONES.push(object)
  }

  createPlayerVirtualFloor(player, position, width = 20, height = 0.01, depth = 20) {
    let playerVirtualFloor = new CANNON.Body({
      position: new CANNON.Vec3(position.x + (position.zoneOffsets?.x || 0), position.y-0.05, position.z + (position.zoneOffsets?.z || 0)),
      shape: new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)),
      collisionFilterGroup: 2
    })
    playerVirtualFloor.userData = { class: 'zone', ownerId: player.id }
    this.world.physicalWorld.addBody(playerVirtualFloor)
  }

  createCurrentPlayerButton(player) {
    const avatarNavItem = document.createElement('i')
    avatarNavItem.classList.add('current-player', 'items-center')
    avatarNavItem.innerHTML = `
      <i class="admin-icon absolute fa-solid fa-binoculars mt-6 ml-2 text-white hidden"></i>
      ${ this.playerId ? `<img class="player-avatar" src="${player.imageUrl}" />` : '<i class="fa-solid fa-face-smile text-2xl"></i>'}
      <span>${player?.username || 'guest'}</span>
    `
    const bottomLeftNav = document.querySelector('nav.bottom-left')
    bottomLeftNav.appendChild(avatarNavItem)
    avatarNavItem.addEventListener('click', this.updateCurrentplayer.bind(this))
    if (this.isAdmin) {
      document.querySelector('.admin-icon')?.classList.remove('hidden')
    } else {
      document.querySelector('.admin-icon')?.classList.add('hidden')
    }
  }

  updateCurrentplayer() {
    if (!this.playerId) {
      return
    }
    const dialogContent = `<form id="formUpdatePlayer" class="h-full w-full flex flex-col justify-center gap-2">
      <fieldset class="flex flex-col justify-center gap-2">
        <label class="text-white">Username</label>
        <input id="inputUsername" type="text" value="${this.players[this.playerId].username}" class="p-4 ring-0 outline-0 border-0" autofocus/>
      </fieldset>
      <button id="btnSubmitDialog" class="button">Update</button>
    </form>`
    this.setDialogContent(dialogContent, () => {
      document.getElementById('formUpdatePlayer').addEventListener('submit', async (event) => {
        event.preventDefault()
        const form = event.target
        const newUsername = form.querySelector('input').value
        await this.network.updateDocument('play_users', this.playerId, {
          username: newUsername
        })
        this.dialog.close()
      })
      this.dialog.showModal()
    })
  }

  updatePlayerInfo(playerId, newData) {
    const { score, username, imageUrl } = newData
    const playerBoard = document.getElementById(`player-board-${playerId}`)
    if (playerBoard) {
      playerBoard.querySelector('.score').innerHTML = `${score} ${this.world?.scoreUnit || ''}`
      playerBoard.querySelector('.text').innerHTML = username
      playerBoard.querySelector('.player-avatar').src = imageUrl || 'https://i.pravatar.cc/300'
    }

    if (playerId == this.playerId) {
      const currentPlayer = document.querySelector('.current-player')
      if (currentPlayer) {
        currentPlayer.querySelector('span').innerHTML = username
        currentPlayer.querySelector('.player-avatar').src = imageUrl || 'https://i.pravatar.cc/300'
      }
    }
  }

  showTradeDialog(targetPlayerId) {
    const targetPlayer = this.players[targetPlayerId]
    if (!targetPlayer) {
      return
    }
    this.dialog.classList.add('items-center', 'justify-center', 'closeonbgclick')
    const dialogContent = `
    <form id="formTrade" class="h-auto w-full flex flex-col justify-center gap-2 ${this.playerId == targetPlayerId ? 'hidden' : ''}">
      <fieldset class="flex flex-col justify-center gap-2">
        <label class="text-white">Available : <span id="currentScore">${this.players[this.playerId].score}</span></label>
        <input id="inputScore" type="number" value="0" min="0" max="${this.players[this.playerId].score}" class="p-4 ring-0 outline-0 border-0" autofocus/>
      </fieldset>
      <input type="hidden" value="${targetPlayerId}" name="targetPlayerId"/>
      <button id="btnSubmitDialog" class="button" disabled>Send to ${targetPlayer.username}</button>
    </form>
    ${ this.isAdmin ? `
      <form id="formSetScore" class="h-auto w-full flex flex-col justify-center gap-2">
        <fieldset class="flex flex-col justify-center gap-2">
          <label class="text-white">${targetPlayer.username}'s score</label>
          <input id="setScore" type="number" min="0" value="${this.players[this.playerId].score}" class="p-4 ring-0 outline-0 border-0"/>
        </fieldset>
        <input type="hidden" value="${targetPlayerId}" name="targetPlayerId"/>
        <button id="btnSubmitDialog" class="button">Set ${targetPlayer.username} score</button>
        </form>
       ` : ''}
    `
    this.setDialogContent(dialogContent, () => {
      document.getElementById('formTrade').addEventListener('submit', this.submitDialogForm.bind(this))
      document.getElementById('formSetScore')?.addEventListener('submit', this.submitDialogForm.bind(this))
      document.getElementById('inputScore').addEventListener('input', (event) => {
        if (event.target.value > 0) {
          document.getElementById('btnSubmitDialog').removeAttribute('disabled')
        } else {
          document.getElementById('btnSubmitDialog').setAttribute('disabled', true)
        }
        if (event.target.value > this.players[this.playerId].score) {
          event.target.value = this.players[this.playerId].score
        }
      })
      this.dialog.showModal()
    })
  }

  setDialogContent(content, callback) {
    this.dialog.innerHTML = content
    callback && callback()
  }

  async submitDialogForm(event) {
    event.preventDefault()
    try {
      const form = event.target
      const targetPlayerId = form.querySelector('input[name="targetPlayerId"]').value
      if (form.id == 'formSetScore') {
        const newScore = parseInt(document.getElementById('setScore').value)
        await this.network.updateDocument('play_users', targetPlayerId, { score: newScore })
      } else {
        const sendAmount = parseInt(document.getElementById('inputScore').value)
        if (sendAmount > 0 && sendAmount <= this.players[this.playerId].score) {
          this.receiptSound.play()
          form.querySelector('fieldset').setAttribute('disabled', true)
          form.querySelector('button').setAttribute('disabled', true)
          await this.network.updateDocument('play_users', this.playerId, {
            score: this.players[this.playerId].score - sendAmount
          })
          await this.network.updateDocument('play_users', targetPlayerId, {
            score: this.players[targetPlayerId].score + sendAmount
          })
        }
      }
    } catch (error) {
      console.warn(error);
    }
    this.dialog.close()
  }

  showObjectContextMenu(actions) {
    actions.forEach(action => {
      document.getElementById(action)?.classList.remove('hidden')
    })
  }

  hideContextMenu() {
    document.querySelectorAll('.context').forEach(el => el.classList.add('hidden'))
  }

  focusToPlayer(playerId) {
    playerId = playerId || this.playerId
    const player = this.players[playerId]
    if (player?.spawnPosition) {
      this.camera.animateTo((player.spawnPosition.x * 2.5), this.camera.instance.position.y, (player.spawnPosition.z * 2.5))
      this.camera.controls.target.set(0,0,0)
    }
  }

  initPostProcessing() {
    // post-processing
    this.composer = new EffectComposer( this.renderer.instance )

    const renderPass = new RenderPass( this.scene, this.camera.instance )
    this.composer.addPass( renderPass )

    this.hoverOutlinePass = new OutlinePass( new THREE.Vector2( this.sizes.width, this.sizes.height ), this.scene, this.camera.instance )
    this.hoverOutlinePass.edgeStrength = 3
    this.hoverOutlinePass.edgeGlow = 0.0
    this.hoverOutlinePass.edgeThickness = 1
    this.hoverOutlinePass.pulsePeriod = 0
    this.hoverOutlinePass.visibleEdgeColor.set('#ffffff')
    this.hoverOutlinePass.hiddenEdgeColor.set('#190a05')
    this.composer.addPass(this.hoverOutlinePass)

    this.selectedOutlinePass = new OutlinePass( new THREE.Vector2( this.sizes.width, this.sizes.height ), this.scene, this.camera.instance )
    this.selectedOutlinePass.edgeStrength = 6
    this.selectedOutlinePass.edgeGlow = 0.8
    this.selectedOutlinePass.edgeThickness = 2
    this.selectedOutlinePass.pulsePeriod = 0
    this.selectedOutlinePass.visibleEdgeColor.set('#FFFF00')
    this.selectedOutlinePass.hiddenEdgeColor.set('#190a05')
    this.composer.addPass(this.selectedOutlinePass)

    const outputPass = new OutputPass()
    this.composer.addPass(outputPass)
  }

  getInteractiveObjectFromUuids(uuids) {
    let firstMatchingObject = null
    let remainingObjectsToSearch = this.INTERACTIVE_OBJECTS.length
    while (firstMatchingObject == null && remainingObjectsToSearch > 0) {
      if (this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].model) {
        this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].model?.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            if (uuids.indexOf(child.uuid) >= 0) {
              firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
            }
          }
        })
      } else {
        if (uuids.indexOf(this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].mesh?.uuid) >= 0) {
          firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
        } else {
          if (uuids.indexOf(this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].uuid) >= 0) {
            firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
          }
        }
      }
      remainingObjectsToSearch--
    }
    return firstMatchingObject
  }

  getInteractiveObjectFromBodyId(bodyId) {
    return this.INTERACTIVE_OBJECTS.find(obj => obj.body?.id == bodyId)
  }

  getInteractiveObjectFromFirebaseId(firebaseId) {
    let firstMatchingObject = null
    let remainingObjectsToSearch = this.INTERACTIVE_OBJECTS.length
    while (firstMatchingObject == null && remainingObjectsToSearch > 0) {
      if (this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].id == firebaseId) {
        firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
      }
      remainingObjectsToSearch--
    }
    return firstMatchingObject
  }

  removeInteractiveObjectBy(id) {
    const idx = this.INTERACTIVE_OBJECTS.findIndex(obj => obj.id == id)
    if (idx >= 0) {
      this.INTERACTIVE_OBJECTS[idx].toDelete = true
    }
  }

  instanciateInteractiveObject(type, props, targetPosition) {
    let elevatedPosition = new THREE.Vector3(0, 3, 0)
    const playerZoneCenter = targetPosition || new THREE.Vector3(0, 1, 0)
    switch(type) {
      case 'Card':
        const card = new Card(this, props.id, props.deckType, props.textureName, props.backTextureName, undefined, undefined, undefined, elevatedPosition, THREE.MathUtils.degToRad(90), undefined, props.isFlipped)
        this.INTERACTIVE_OBJECTS.push(card)
        card.moveTo(playerZoneCenter, this.playerId, ()=> { card.onDropped() })
        break
      case 'Piece':
        // if online, pieces are created by play_pieces snapshot listener in World.js
        if (!this.enableOnline) {
          const piece = new Piece({
            experience: this,
            id: props.id,
            deckType: props.deckType,
            modelName: props.modelName,
            startingPosition: new THREE.Vector3(...playerZoneCenter),
            startingRotationInRad: props.rotationY ? THREE.MathUtils.degToRad(props.rotationY) : undefined
          })
          if (props.ownerId) {
            piece.setOwner(props.ownerId)
          }
          this.INTERACTIVE_OBJECTS.push(piece)
        }
        break
      default:
        console.error('instanciateInteractiveObject: unknown type', type)
    }
  }

  initGoDice() {
    GoDice.prototype.onDiceConnected = (diceId, diceInstance) => {
      // console.log('Dice connected: ', diceId, diceInstance.GoDiceService.device.name)
      this.alertSound.play()
    }
    
    GoDice.prototype.onRollStart = (diceId) => {
      console.log('Roll Start: ', diceId)
    }
    
    GoDice.prototype.onStable = (diceId, value, xyzArray) => {
      console.log('Stable event: ', diceId, value)
      this.controls.dispatchEvent(new Event('rolled'))
    }
    
    GoDice.prototype.onTiltStable = (diceId, xyzArray, value) => {
      console.log('TiltStable: ', diceId, xyzArray)
    }
    
    GoDice.prototype.onFakeStable = (diceId, value, xyzArray) => {
      console.log('FakeStable: ', diceId, value)
    }
    
    GoDice.prototype.onMoveStable = (diceId, value, xyzArray) => {
      console.log('MoveStable: ', diceId, value)
    }
    
    GoDice.prototype.onBatteryLevel = (diceId, batteryLevel) => {
      console.log('BatteryLevel: ', diceId, batteryLevel)
    }
    
    GoDice.prototype.onDiceColor = (diceId, color) => {
      console.log('DiceColor: ', diceId, color)
    }
  }

  openBluetoothConnectionDialog() {
    const newDice = new GoDice()
    newDice.requestDevice()
  }

  toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
    } else if (document.exitFullscreen) {
        document.exitFullscreen()
    }
  }

  toggleMusic() {
    this.music.playing() ? this.music.pause() : this.music.play()
  }

  getWindowCoordinates(vec3Pos) {
    vec3Pos.project(this.camera.instance);
    var x = Math.round((vec3Pos.x + 1) * this.renderer.instance.domElement.offsetWidth / 2);
    var y = Math.round((1 - vec3Pos.y) * this.renderer.instance.domElement.offsetHeight / 2);
    return { x , y };
  }

  resize() {
    this.camera.resize()
    this.renderer.resize()
    this.uiRenderer.setSize(window.innerWidth, window.innerHeight)
  }

  update() {
    this.camera.update()
    this.world.update()
    this.renderer.update()
    this.uiRenderer.render(this.uiScene, this.camera.instance)
    this.composer.render()
  }

  destroy() {
    this.sizes.removeEventListener('resize')
    this.time.removeEventListener('tick')
    this.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose()
        for (const key in child.material) {
          const value = child.material[key]

          if (value && typeof value.dispose == 'function') [
            value.dispose()
          ]
        }
      }
    })
    this.camera.controls.dispose()
    this.renderer.instance.clear()
    this.renderer.instance.dispose()

    if (this.debug.active) {
      this.debug.gui.destroy()
    }
  }
}