import { initializeApp } from 'firebase/app'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'
import { io } from 'socket.io-client'

export default class NetworkService extends EventTarget {
  constructor(experience) {
    super()
    this.experience = experience
    this.socket = null
    this.app = null
    this.db = null
    this.initFirebase()
  }

  async initOnline() {
    if (!this.experience.playerId) {
      return
    }
    const doc = await this.fetchDocument('play_users', this.experience.playerId)
    if (doc) {
      const { cameraX, cameraY, cameraZ } = doc?.data()
      if (
        typeof cameraX == 'number' &&
        typeof cameraY == 'number' &&
        typeof cameraZ == 'number'
      ) {
        this.dispatchEvent(new CustomEvent('cameraPosition', { detail: { x: cameraX, y: cameraY, z: cameraZ } }))
      }
    }
  }

  initFirebase() {
    const firebaseConfig = {
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID
    }

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig)
    this.db = getFirestore()
  }

  initSocketIO() {
    const server = localStorage.getItem('socketioserver') || 'wss://raphkunldn.ddns.net:3030'
    this.socket = io(server, {
      transports: ['websocket'],
      auth: {
        token: this.experience.playerId
      }
    })

    this.socket.on('connect', () => {
      console.log('connected to socketIO server')
      this.socket.emit('ping', { playerId: this.experience.playerId, username: this.experience.players[this.experience.playerId]?.username })
    })

    this.socket.on('playmove', (message, serverOffset) => {
      this.experience.dispatchEvent(new CustomEvent('message', { detail: { message, serverOffset } }))
    })

    this.socket.on('disconnect', () => {
      console.log('socketIO server disconnected')
    })

    this.socket.on('connect_error', (err) => {
      console.log('socketIO server connection error')
      // the reason of the error, for example 'xhr poll error'
      console.log(err.message)
    
      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description)
    
      // some additional context, for example the XMLHttpRequest object
      console.log(err.context)
    })
  }

  async broadcastEvent(data) {
    if (!this.experience.playerId) {
      return
    }
    try {
      data = {
        ...data,
        emitterId: this.experience.playerId
      }
      if (this.socket?.connected) {
        this.socket.emit('playmove', data, -1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async fetchDocument(collectionName, docId) {
    if (!docId) {
      return null
    }
    const document = await getDoc(doc(this.db, collectionName, docId))
    return document
  }

  async fetchDocuments(collectionName) {
    let result = []
    const querySnapshot = await getDocs(collection(this.db, collectionName))
    querySnapshot.forEach((doc) => {
      result.push({...doc.data(), id: doc.id})
    })
    return result
  }

  async updateDocument(collectionName, docId, data) {
    await setDoc(doc(this.db, collectionName, docId), {...data, _lmt: serverTimestamp() }, { merge: true })
  }

  async createDocument(collectionName, data) {
    const res = await addDoc(collection(this.db, collectionName), {...data, _lmt: serverTimestamp()})
    return res
  }

  async deleteDocument(collectionName, docId) {
    await deleteDoc(doc(this.db, collectionName, docId))
  }
}