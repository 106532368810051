import * as CANNON from 'cannon-es'
import gsap from 'gsap'
import { Howl } from 'howler'
import * as THREE from 'three'
import { isMobile } from '../Utils/Utils'
import Deck from './Deck'

// TODO: create InteractiveObject abstract class
export default class Piece {
  constructor({ experience, id, modelName, startingPosition, startingRotationInRad, deckType }) {
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.world = this.experience.world
    this.debug = this.experience.debug
    this.controls = this.experience.controls
    this.network = this.experience.network
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera

    this.id = id
    this.modelName = modelName
    this.deckType = deckType
    this.isDragged = false
    this.nbMoves = 0
    this.pieceDroppedSfx = new Howl({ src: ['sounds/drop.mp3'] })

    this.initModel(startingPosition)
    this.initPhysic()

    if (startingRotationInRad) {
      this.rotateY(startingRotationInRad)
    }

    if (this.model) {
      this.controls.addEventListener(this.model?.uuid, () => { 
        if (!this.experience.playerId) {
          return
        }
        this.onSelect(this.experience.playerId)
      })
    }
  }

  initModel(startingPosition) {
    this.model = this.resourcesManager.items[this.modelName].scene.clone()
    const position = startingPosition ? startingPosition : new THREE.Vector3(0,0,0)
    if (this.model.userData?.scale) {
      this.model.scale.set(this.model.userData?.scale, this.model.userData?.scale, this.model.userData?.scale)
    }
    this.model.position.set(position.x, position.y, position.z)
    this.scene.add(this.model)
    
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true
      }
    })
    this.model.helperBox = new THREE.BoxHelper(this.model, 0xffff00)
    this.model.helperBox.geometry.computeBoundingBox()

    if (this.debug.active) {
      this.scene.add(this.model.helperBox)
    }
  }

  initPhysic(position) {
    const startingPosition = position || this.model.position || new THREE.Vector3(0,0,0)
    const max = this.model.helperBox.geometry.boundingBox.max
    const min = this.model.helperBox.geometry.boundingBox.min
    const width = max.x - min.x
    const height = max.y - min.y
    const depth = max.z - min.z
    
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))
    const material = new CANNON.Material({
      friction: 10,
      restitution: 0, 
      contactEquationStiffness: 6000,
      contactEquationRelaxation: 0.000001
    })

    this.body = new CANNON.Body({
      mass: .1,
      position: new CANNON.Vec3(startingPosition.x, startingPosition.y, startingPosition.z),
      shape: shape,
      material: material,
      collisionFilterGroup: 2
    })
    this.world.physicalWorld.addBody(this.body)
    
    this.body.addEventListener('sleep', async () => {
      if (this.experience.enableOnline && this.nbMoves > 0 && this.emitterId == this.experience.playerId && this.toDelete !== true) {
        const {x, y, z} = this.body.position
        await this.network.updateDocument('play_pieces', this.id, { x, y, z, ownerId: this.ownerId || null })
      }

      this.nbMoves++
    })

    this.body.addEventListener('collide', (event) => {
      if (this.toDelete !== true) {
        const collidedObject = this.experience.getInteractiveObjectFromBodyId(event.body.id)
        if (collidedObject instanceof Deck && collidedObject.deckType == this.deckType) {
          collidedObject.onCollide(this)
        } else if (event.body?.userData?.class == 'zone') {
          this.setOwner(event.body?.userData?.ownerId)
        }
      }
    })
  }

  setOwner(playerId) {
    this.ownerId = playerId
  }

  moveTo(targetPosition, emitterId, callBack) {
    this.emitterId = emitterId

    const tl = new gsap.timeline({
      defaults: {
        duration: 0.2,
        delay: 0.1
      },
      onComplete: () => {
        callBack && callBack()
      }
    })
    tl.to(this.body.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z
    })
  }

  async rotateY(angleInRad, emitterId) {
    this.model.rotateY(angleInRad)
    this.body.quaternion.copy(this.model.quaternion)

    if (this.experience.enableOnline && this.nbMoves > 0 && emitterId == this.experience.playerId) {
      const euler = new THREE.Euler()
      const rotation = euler.setFromQuaternion(this.model.quaternion)
      const radians = rotation.y > 0 ? rotation.y : 2 * Math.PI + rotation.y
      const degrees = THREE.MathUtils.radToDeg(radians)
      await this.network.updateDocument('play_pieces', this.id, { rotationY: Math.round(degrees) })
    }
    this.nbMoves++
  }

  async rotateLeft(emitterId) {
    this.rotateY(THREE.MathUtils.degToRad(45), emitterId)
  }

  async rotateRight(emitterId) {
    this.rotateY(THREE.MathUtils.degToRad(-45), emitterId)
  }

  async reset(emitterId) {
    this.model.rotation.x = 0
    this.model.rotation.y = 0
    this.model.rotation.z = 0
    this.body.quaternion.copy(this.model.quaternion)
    this.body.applyImpulse(new CANNON.Vec3(0, -.1, 0))
    if (this.experience.enableOnline && emitterId == this.experience.playerId) {
      const {x, y, z} = this.body.position
      await this.network.updateDocument('play_pieces', this.id, { isFlipped: false, x, y, z})
    }
  }

  onSelect(emitterId) {
    console.log(`${this.modelName} piece ${this.id} selected by ${emitterId} ${emitterId === this.experience.playerId ? '(me)' : ''}`)
    if (isMobile()) {
      this.experience.currentObjectSelection = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == this.id)
      this.experience.showObjectContextMenu(['btnRotateLeft', 'btnRotateRight'])
    }
  }

  onDropped() {
    this.body.applyImpulse(new CANNON.Vec3(0, .1 * 1 / 60, 0))
    this.pieceDroppedSfx?.play()
  }

  update() {
    this.model.position.copy(this.body.position)
    this.model.quaternion.copy(this.body.quaternion)

    if (this.debug.active && this.experience.debugEl) {
      this.experience.debugEl.innerHTML = THREE.MathUtils.radToDeg(this.model.rotation.y) % 360 + ' ' + this.model.rotation.y
    }
  }

  dispose(emitterId) {
    this.experience.removeInteractiveObjectBy(this.id)
    this.scene.remove(this.model)
    this.body.removeEventListener('sleep')
    this.body.removeEventListener('collide')
    if (this.experience.enableOnline && emitterId == this.experience.playerId) {
      this.network.deleteDocument('play_pieces', this.id)
    }
  }
}