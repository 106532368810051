import * as CANNON from 'cannon-es'
import gsap from 'gsap'
import * as THREE from 'three'
import assets from '../../../static/assets'
import { uuidv4 } from '../Utils/Utils'
import Card from './Card'

// TODO: create InteractiveObject abstract class
export default class Deck {
  constructor({
    experience,
    id,
    modelName = 'deckModel',
    textureName,
    deckType,
    entities = [],
    nbEntities,
    entityModelName,
    isSearchable = false,
    isLocked = false,
    absorbOnTop = true,
    isDealLocked = false,
    flipOnDeal = false,
    position,
    rotationY
  }) {
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.world = this.experience.world
    this.debug = this.experience.debug
    this.controls = this.experience.controls
    this.network = this.experience.network

    this.id = id
    this.deckType = deckType
    this.isLocked = isLocked
    this.isDealLocked = isDealLocked
    this.isSearchable = isSearchable
    this.flipOnDeal = flipOnDeal
    this.modelName = modelName
    this.entities = entities
    this.entityModelName = entityModelName
    this.nbEntities = nbEntities || this.entities.length
    this.label = this.entities?.length?.toString()
    this.absorbOnTop = absorbOnTop
    this.nbMoves = 0

    this.deckShuffledSfx = new Howl({ src: ['sounds/shuffle.mp3'], volume: 0.1, })

    if (textureName) {
      this.initTextures(textureName)
    }
    this.initModel(position)
    this.initPhysic(position)

    if (rotationY) {
      this.initialRotationY = rotationY
      this.rotateY(rotationY)
    }

    if (this.model) {
      this.controls.addEventListener(this.model?.uuid, () => {
       this.onSelect(this.experience.playerId)
      })
    }

    this.initListeners()
  }

  initTextures(textureName) {
    this.textures = {}
    this.textures.color = this.resourcesManager.items[textureName]
    this.textures.color.colorSpace = THREE.SRGBColorSpace
  }

  initModel(initialPosition) {
    this.model = this.resourcesManager.items[this.modelName].scene.clone()
    if (this.model.userData?.scale) {
      this.model.scale.set(this.model.userData?.scale, this.model.userData?.scale, this.model.userData?.scale)
    }
    const position = initialPosition || new THREE.Vector3(0,0,0)
    this.model.position.set(position.x, position.y, position.z)
    this.refreshTooltip()
    this.scene.add(this.model)

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true
        if (child.name.indexOf('deck-face') >= 0) {
          child.material = new THREE.MeshStandardMaterial({ map: this.textures.color }),
          child.material.map.needsUpdate = true
        }
      }
    })

    this.model.helperBox = new THREE.BoxHelper(this.model, 0xffff00)
    this.model.helperBox.geometry.computeBoundingBox()
    if (this.debug.active) {
      this.scene.add(this.model.helperBox)
    }
  }

  initListeners() {
    if (this.isSearchable) {
      this.experience.addEventListener(this.id, (event) => {
        if (event.detail?.textureName) {
          this.experience.dialog.close()
          this.deal(event.detail.textureName, event.detail?.playerId || this.experience.playerId, event.detail?.forceCreation)
        }
      })
    }
    this.world.addEventListener(this.id, (event) => {
      if (Array.isArray(event.detail?.entities)) {
        this.setEntities(event.detail.entities)
        if (this.experience.dialog.dataset.callerId == this.id) {
          this.renderDeckEntitiesInDialog()
        }
      }
      this.isDealLocked = event.detail?.isDealLocked == true
    })
  }

  renderDeckEntitiesInDialog(callback) {
    const content = `<div class="container">
      ${
        this.entities?.length ? this.entities.toReversed().map(({textureName}) => {
          const path = assets.find(({name}) => name == textureName)?.path
          return `<div data-name=${textureName} class="card" style="background-image: url(${path})"></div>`
        }).join('') : ''
      }
      </div>
      <form id="formDealDialog" class="footer">
        <fieldset class="flex items-center gap-2">
        <button id="btnSubmitDialog" type="submit" class="button" disabled>${this.experience.isAdmin ? 'Deal to' : 'Deal'}</button>
        ${
          (this.experience.isAdmin ? Object.keys(this.experience.players) : [this.experience.playerId]).map(playerId => {
            const username = this.experience.players[playerId].username
            return `<div>
              <input type="radio" id="input-radio-${playerId}" value=${playerId} class="inputDialog ${this.experience.isAdmin ? '' : 'hidden'}" name="playerId" ${this.experience.playerId == playerId ? 'checked' : ''}/>
              <label for="input-radio-${playerId}" class="${this.experience.isAdmin ? '' : 'hidden'}">${username}</label>
            </div>`
          }).join('')
        }
        </fieldset>
      </form>
      `
      this.experience.setDialogContent(content, callback)
  }

  updateTexture(textureName) {
    if (this.resourcesManager.items[textureName]) {
      this.textures.color = this.resourcesManager.items[textureName]
      this.textures.color.colorSpace = THREE.SRGBColorSpace
    } else {
      console.error(`Texture ${textureName} not found`)
    }
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true
        if (child.name.indexOf('deck-face') >= 0) {
          child.material = new THREE.MeshStandardMaterial({ map: this.textures.color }),
          child.material.map.needsUpdate = true
        }
      }
    })
  }

  initPhysic(pos) {
    const max = this.model.helperBox.geometry.boundingBox.max
    const min = this.model.helperBox.geometry.boundingBox.min
    const width = max.x - min.x
    const height = max.y - min.y
    const depth = max.z - min.z
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))
    const material = new CANNON.Material({
      friction: 10,
      restitution: 0, 
      contactEquationStiffness: 6000,
      contactEquationRelaxation: 0.000001
    })

    this.body = new CANNON.Body({
        mass: 1000,
        position: new CANNON.Vec3(pos.x, pos.y, pos.z),
        shape: shape,
        material,
        collisionFilterGroup: 2
    })
    this.world.physicalWorld.addBody(this.body)

    this.body.addEventListener('sleep', async () => {
      if (this.experience.enableOnline && this.nbMoves > 0 && this.emitterId == this.experience.playerId) {
        const {x, y, z} = this.body.position
        await this.network.updateDocument('play_decks', this.id, { x, y, z })
      }

      this.nbMoves++
    })

    this.body.addEventListener('collide', (event) => {
      const collidedObject = this.experience.getInteractiveObjectFromBodyId(event.body.id)
      if (collidedObject instanceof Card && collidedObject.deckType == this.deckType) {
        this.onCollide(collidedObject)
      }
    })
  }

  async rotateY(angleInRad, emitterId) {
    if (this.isLocked && emitterId) {
      return
    }
    this.model.rotateY(angleInRad)
    this.body.quaternion.copy(this.model.quaternion)

    if (this.experience.enableOnline && this.nbMoves > 0 && emitterId == this.experience.playerId) {
      const euler = new THREE.Euler()
      const rotation = euler.setFromQuaternion(this.model.quaternion)
      const radians = rotation.y > 0 ? rotation.y : 2 * Math.PI + rotation.y
      const degrees = THREE.MathUtils.radToDeg(radians)
      await this.network.updateDocument('play_decks', this.id, { rotationY: Math.round(degrees) })
    }
    this.nbMoves++
  }

  async rotateLeft(emitterId) {
    this.rotateY(THREE.MathUtils.degToRad(45), emitterId)
  }

  async rotateRight(emitterId) {
    this.rotateY(THREE.MathUtils.degToRad(-45), emitterId)
  }

  onSelect(emitterId) {
    // console.log(`Deck ${this.id} selected by ${emitterId} ${emitterId === this.experience.playerId ? '(me)' : ''}`)
    if (!this.isSearchable) {
      const textureName = this.entities?.length ? this.entities[this.entities.length - 1].textureName : null
      this.deal(textureName, emitterId)
    } else {
      if (this.experience.playerId == emitterId) {
        this.experience.dialog.dataset.callerId = this.id
        this.renderDeckEntitiesInDialog(() => {
          this.experience.dialog.showModal()
          document.getElementById('formDealDialog').addEventListener('submit', (event) => {
            event.preventDefault()
            const playerId = document.querySelector('input.inputDialog[name="playerId"]:checked').value
            this.experience.dispatchEvent(
            new CustomEvent(
              this.experience.dialog.dataset.callerId, 
              {
                detail: {
                  textureName: this.experience.dialogSelectedElement.dataset.name,
                  playerId,
                  forceCreation: this.experience.isAdmin
                }
              }
            ))
            this.experience.dialogSelectedElement = null
          })
        })
      }
    }
  }

  async deal(textureName, emitterId, forceCreation = false) {
    const objectType = this.entityModelName ? 'Piece' : 'Card'
    if (this.isDealLocked && !this.experience.isAdmin) {
      return
    }
    if (objectType == 'Card' && !textureName) {
      return
    }
    let position = {x: 5, y: 1, z: -5}
    if (this.experience.players[emitterId]) {
      position = this.experience.players[emitterId]?.spawnPosition
    }
    const {x, y, z} = position
    if (this.nbEntities > 0) {
      this.nbEntities--
      let entity
      if (objectType == 'Card') {
        entity = textureName ? this.entities.find(e => e.textureName == textureName) : this.entities.pop()
        if (entity) {
          this.entities = this.entities.filter(e => e.textureName != entity.textureName)
        }
      } else {
        entity = { modelName: this.entityModelName, type: 'Piece', deckType: this.deckType }
      }
      this.refreshTooltip()
      if (this.entities?.length && objectType == 'Card') {
        this.updateTexture(this.entities[this.entities.length - 1].backTextureName)
      }
      let res
      const spawnPosition = new THREE.Vector3(x || 5, y || 1, z || -5)
      const rotationX = this.entityModelName ? 0 : 90
      const isFlipped = this.flipOnDeal && objectType == 'Card'
      if (entity && this.experience.enableOnline && (emitterId == this.experience.playerId || forceCreation)) {
        const data = {
          nbEntities: this.nbEntities,
          ...(this.modelName == 'deckModel' ? {entities: this.entities} :{})
        }
        await this.network.updateDocument('play_decks', this.id, data)
        const collectionName = this.entityModelName ? 'play_pieces' : 'play_cards'
        res = await this.network.createDocument(collectionName, {...entity, rotationX, ...spawnPosition, ownerId: emitterId, isFlipped})
      } else if (entity && !this.experience.enableOnline) {
        this.experience.instanciateInteractiveObject(objectType, {...entity, id: uuidv4(), ownerId: emitterId, rotationX, isFlipped}, spawnPosition)
      } else if (!entity) {
        console.warn('No entity to deal')
      }
    }
  }

  moveTo(targetPosition, emitterId) {
    if (this.isLocked) {
      return
    }
    const tl = new gsap.timeline({
      defaults: {
        duration: 0.22,
        delay: 0.1,
        onComplete: () => {
          this.body.applyImpulse(new CANNON.Vec3(0, 0, 0))
        }
      }
    })
    tl.to(this.body.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z
    })

    this.emitterId = emitterId
  }

  reset(emitterId) {
    if (this.isLocked && emitterId) {
      return
    }
    this.body.position.y = 1
    this.model.rotation.x = 0
    this.model.rotation.y = 0
    this.model.rotation.z = 0
    if (this.initialRotationY) {
      this.rotateY(this.initialRotationY, emitterId)
    }
    this.body.applyImpulse(new CANNON.Vec3(0, 0, 0))
  }

  onCollide(droppedObject) {
    if (droppedObject.deckType == this.deckType && droppedObject.toDelete !== true) {
      this.absorb(droppedObject)
    }
  }

  // TODO: add toTopOrBottom parameter
  async absorb(entity) {
    if (this.modelName == 'deckModel') {
      if (!this.entities.map(({id})=>id).includes(entity.id)) {
        entity?.dispose(entity.emitterId)
        const rawEntityData = {
          deckType: entity.deckType,
          textureName: entity.textureName,
          backTextureName: entity.backTextureName,
          type: 'Card'
        }
        if (this.absorbOnTop) {
          this.entities.push(rawEntityData)
        }  else {
          this.entities.unshift(rawEntityData)
        }
        this.updateTexture(this.entities[this.entities.length - 1].backTextureName)
      }
    } else {
      this.nbEntities++
      entity?.dispose(entity.emitterId)
    }
    this.refreshTooltip()
    if (this.experience.enableOnline && entity.emitterId == this.experience.playerId) {
      const data = {
        nbEntities: this.nbEntities,
        ...(this.modelName == 'deckModel' ? {entities: this.entities} :{})
      }
      this.network.updateDocument('play_decks', this.id, data)
    }
  }

  shuffle(emitterId) {
    const shuffledEntities = this.entities.sort(() => Math.random() - 0.5)
    if (this.modelName == 'deckModel') {
      if (this.experience.enableOnline && emitterId == this.experience.playerId) {
        this.network.updateDocument('play_decks', this.id, { entities: shuffledEntities })
      } else {
        this.entities = shuffledEntities
        this.refreshTooltip()
        this.updateTexture(this.entities[this.entities.length - 1].backTextureName)
      }
      this.deckShuffledSfx?.play()
    }
  }

  setEntities(entities) {
    this.entities = entities
    this.refreshTooltip()
    if (this.modelName == 'deckModel') {
      this.updateTexture(this.entities[this.entities.length - 1].backTextureName)
    }
  }

  refreshTooltip() {
    if (this.modelName == 'deckModel') {
      this.label = this.entities?.length.toString()
      this.model.scale.y = this.entities?.length ? Card.THICKNESS * this.entities?.length : 0
      this.model.visible = this.entities?.length > 0
    } else {
      this.label = this.nbEntities.toString()
    }
  }

  update() {
    this.model.position.copy(this.body.position)
    this.model.quaternion.copy(this.body.quaternion)
    this.model.helperBox.update()
  }

  dispose(emitterId) {
    this.model.removeFromParent()
    this.experience.removeInteractiveObjectBy(this.id)
    this.body.removeEventListener('sleep')
    this.body.removeEventListener('collide')
    if (this.experience.enableOnline && emitterId == this.experience.playerId) {
      this.network.deleteDocument('play_decks', this.id)
    }
  }
}