export default [
  // {
  //   name: 'environmentMapTexture',
  //   type: 'cubeTexture',
  //   path: [
  //     '/textures/environmentMap/px.jpg',
  //     '/textures/environmentMap/nx.jpg',
  //     '/textures/environmentMap/py.jpg',
  //     '/textures/environmentMap/ny.jpg',
  //     '/textures/environmentMap/pz.jpg',
  //     '/textures/environmentMap/nz.jpg',
  //   ]
  // },
  {
    name: 'monopolyColorNormal',
    type: 'texture',
    path: 'textures/tiles/monopoly/01_-_Default_normal.png'
  },
  {
    name: 'monopolyColorTexture',
    type: 'texture',
    path: 'textures/tiles/monopoly/01_-_Default_baseColor.png'
  },
  // {
  //   name: 'chessboardColorTexture',
  //   type: 'texture',
  //   path: 'textures/tiles/chessboard.jpg'
  // },
  {
    name: 'chanceBackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_back.jpg'
  },
  {
    name: 'communityCoverTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/community_back.png'
  },
  {
    name: 'chance_ATB',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_ATB.jpg'
  },
  {
    name: 'chance_ATGC200',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_ATGC200.jpg'
  },
  {
    name: 'chance_ATIA',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_ATIA.jpg'
  },
  {
    name: 'chance_ATSCP',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_ATSCP.jpg'
  },
  {
    name: 'chance_ATTNRR',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_ATTNRR.jpg'
  },
  {
    name: 'chance_ATTNU',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_ATTNU.jpg'
  },
  {
    name: 'chance_BPYD50',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_BPYD50.jpg'
  },
  {
    name: 'chance_GB3S',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_GB3S.jpg'
  },
  {
    name: 'chance_GOOJF',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_GOOJF.jpg'
  },
  {
    name: 'chance_GTJ',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_GTJ.jpg'
  },
  {
    name: 'chance_MGR25PH100PH',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_MGR25PH100PH.jpg'
  },
  {
    name: 'chance_PPT15',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_MGR25PH100PH.jpg'
  },
  {
    name: 'chance_TAROTRC200',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_TAROTRC200.jpg'
  },
  {
    name: 'chance_YBALMC150',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_YBALMC150.jpg'
  },
  {
    name: 'chance_YHBAECOTBPEP50',
    type: 'texture',
    path: 'textures/cards/monopoly/chance_YHBAECOTBPEP50.jpg'
  },
  {
    name:'community_ATGC200',
    type: 'texture',
    path: 'textures/cards/monopoly/community_ATGC200.jpg'
  },
  {
    name:'community_BEIYFC200',
    type: 'texture',
    path: 'textures/cards/monopoly/community_BEIYFC200.jpg'
  },
  {
    name:'community_DFP50',
    type: 'texture',
    path: 'textures/cards/monopoly/community_DFP50.jpg'
  },
  {
    name:'community_FSOSYG45',
    type: 'texture',
    path: 'textures/cards/monopoly/community_FSOSYG45.jpg'
  },
  {
    name:'community_GOOC50FEP',
    type: 'texture',
    path: 'textures/cards/monopoly/community_GOOC50FEP.jpg'
  },
  {
    name:'community_GOOJF',
    type: 'texture',
    path: 'textures/cards/monopoly/community_GOOJF.jpg'
  },
  {
    name:'community_GTJ',
    type: 'texture',
    path: 'textures/cards/monopoly/community_GTJ.jpg'
  },
  {
    name:'community_ITRC20',
    type: 'texture',
    path: 'textures/cards/monopoly/community_ITRC20.jpg'
  },
  {
    name:'community_LIMC100',
    type: 'texture',
    path: 'textures/cards/monopoly/community_LIMC100.jpg'
  },
  {
    name:'community_PH100',
    type: 'texture',
    path: 'textures/cards/monopoly/community_PH100.jpg'
  },
  {
    name:'community_PST150',
    type: 'texture',
    path: 'textures/cards/monopoly/community_PST150.jpg'
  },
  {
    name:'community_RFS25',
    type: 'texture',
    path: 'textures/cards/monopoly/community_RFS25.jpg'
  },
  {
    name:'community_XFMC100',
    type: 'texture',
    path: 'textures/cards/monopoly/community_XFMC100.jpg'
  },
  {
    name:'community_YAAFSR40PH115PH',
    type: 'texture',
    path: 'textures/cards/monopoly/community_YAAFSR40PH115PH.jpg'
  },
  {
    name:'community_YHWSPIABCC10',
    type: 'texture',
    path: 'textures/cards/monopoly/community_YHWSPIABCC10.jpg'
  },
  {
    name:'community_YI100',
    type: 'texture',
    path: 'textures/cards/monopoly/community_YI100.jpg'
  },
  {
    name: 'deedCard1Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_1.jpg'
  },
  {
    name: 'deedCard1BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_1_back.jpg'
  },
  {
    name: 'deedCard2Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_2.jpg'
  },
  {
    name: 'deedCard2BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_2_back.jpg'
  },
  {
    name: 'deedCard3Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_3.jpg'
  },
  {
    name: 'deedCard3BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_3_back.jpg'
  },
  {
    name: 'deedCard4Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_4.jpg'
  },
  {
    name: 'deedCard4BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_4_back.jpg'
  },
  {
    name: 'deedCard5Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_5.jpg'
  },
  {
    name: 'deedCard5BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_5_back.jpg'
  },
  {
    name: 'deedCard6Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_6.jpg'
  },
  {
    name: 'deedCard6BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_6_back.jpg'
  },
  {
    name: 'deedCard7Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_7.jpg'
  },
  {
    name: 'deedCard7BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_7_back.jpg'
  },
  {
    name: 'deedCard8Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_8.jpg'
  },
  {
    name: 'deedCard8BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_8_back.jpg'
  },
  {
    name: 'deedCard9Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_9.jpg'
  },
  {
    name: 'deedCard9BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_9_back.jpg'
  },
  {
    name: 'deedCard10Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_10.jpg'
  },
  {
    name: 'deedCard10BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_10_back.jpg'
  },
  {
    name: 'deedCard11Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_11.jpg'
  },
  {
    name: 'deedCard11BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_11_back.jpg'
  },
  {
    name: 'deedCard12Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_12.jpg'
  },
  {
    name: 'deedCard12BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_12_back.jpg'
  },
  {
    name: 'deedCard13Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_13.jpg'
  },
  {
    name: 'deedCard13BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_13_back.jpg'
  },
  {
    name: 'deedCard14Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_14.jpg'
  },
  {
    name: 'deedCard14BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_14_back.jpg'
  },
  {
    name: 'deedCard15Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_15.jpg'
  },
  {
    name: 'deedCard15BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_15_back.jpg'
  },
  {
    name: 'deedCard16Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_16.jpg'
  },
  {
    name: 'deedCard16BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_16_back.jpg'
  },
  {
    name: 'deedCard17Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_17.jpg'
  },
  {
    name: 'deedCard17BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_17_back.jpg'
  },
  {
    name: 'deedCard18Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_18.jpg'
  },
  {
    name: 'deedCard18BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_18_back.jpg'
  },
  {
    name: 'deedCard19Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_19.jpg'
  },
  {
    name: 'deedCard19BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_19_back.jpg'
  },
  {
    name: 'deedCard20Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_20.jpg'
  },
  {
    name: 'deedCard20BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_20_back.jpg'
  },
  {
    name: 'deedCard21Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_21.jpg'
  },
  {
    name: 'deedCard21BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_21_back.jpg'
  },
  {
    name: 'deedCard22Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_22.jpg'
  },
  {
    name: 'deedCard22BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/deed_22_back.jpg'
  },
  {
    name: 'railRoadCard1Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_1.jpg'
  },
  {
    name: 'railRoadCard1BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_1_back.jpg'
  },
  {
    name: 'railRoadCard2Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_2.jpg'
  },
  {
    name: 'railRoadCard2BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_2_back.jpg'
  },
  {
    name: 'railRoadCard3Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_3.jpg'
  },
  {
    name: 'railRoadCard3BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_3_back.jpg'
  },
  {
    name: 'railRoadCard4Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_4.jpg'
  },
  {
    name: 'railRoadCard4BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/railroad_4_back.jpg'
  },
  {
    name: 'utilityCard1Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/electric_company.jpg'
  },
  {
    name: 'utilityCard1BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/electric_company_back.jpg'
  },
  {
    name: 'utilityCard2Texture',
    type: 'texture',
    path: 'textures/cards/monopoly/water_works.jpg'
  },
  {
    name: 'utilityCard2BackTexture',
    type: 'texture',
    path: 'textures/cards/monopoly/water_works_back.jpg'
  },
  // {
  //   name: 'chessBoardModel',
  //   type: 'gltfModel',
  //   path: 'models/chess/chess_board.gltf'
  // },
  // {
  //   name: 'chessSetModel',
  //   type: 'gltfModel',
  //   path: 'models/chess/chess_set.gltf'
  // },
  {
    name: 'hatModel',
    type: 'gltfModel',
    path: 'models/monopoly/top_hat.gltf'
  },
  {
    name: 'carModel',
    type: 'gltfModel',
    path: 'models/monopoly/car.gltf'
  },
  {
    name: 'ironModel',
    type: 'gltfModel',
    path: 'models/monopoly/iron.gltf'
  },
  {
    name: 'shipModel',
    type: 'gltfModel',
    path: 'models/monopoly/ship.gltf'
  },
  {
    name: 'deckModel',
    type: 'gltfModel',
    path: 'models/deck.gltf'
  },
  {
    name: 'diceModel',
    type: 'gltfModel',
    path: 'models/dice.gltf'
  },
  {
    name: 'coinModel',
    type: 'gltfModel',
    path: 'models/coin.gltf'
  },
  {
    name: 'hotelModel',
    type: 'gltfModel',
    path: 'models/monopoly/hotel.gltf'
  },
  {
    name: 'houseModel',
    type: 'gltfModel',
    path: 'models/monopoly/house.gltf'
  },
  {
    name: 'wheelbarrowModel',
    type: 'gltfModel',
    path: 'models/monopoly/wheelbarrow.gltf'
  },
  {
    name: 'thimbleModel',
    type: 'gltfModel',
    path: 'models/monopoly/thimble.gltf'
  },
  {
    name: 'cannonModel',
    type: 'gltfModel',
    path: 'models/monopoly/cannon.gltf'
  }
]